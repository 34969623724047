window.addEventListener("load", () => {
  const regulationsCheckBox = document.getElementById("regulations_accept_all");

  if (regulationsCheckBox)
    regulationsCheckBox.onclick = function () {
      let checkboxes = document.querySelectorAll('input[type="checkbox"]');
      for (let checkbox of checkboxes) {
        if (!checkbox.disabled) checkbox.checked = this.checked;
      }
    };
});
