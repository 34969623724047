window.addEventListener("turbo:load", () => {
  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS";
    }
    return "Other";
  };

  const OS = getMobileOS();

  if (!getCookie("pwa-banner-closed") && OS != "Other") {
    document
      .querySelector(".smartbanner")
      .classList.add(OS == "iOS" ? "smartbanner--ios" : "smartbanner--android");

    window.addEventListener("beforeinstallprompt", (pwaEvent) => {
      pwaEvent.preventDefault();
      window.pwaEvent = pwaEvent;
      document.querySelector(".smartbanner").classList.remove("d-none");
      document
        .querySelector(".smartbanner__button")
        .addEventListener("click", () => {
          pwaEvent.prompt();
        });
    });
  }
  document.querySelector(".smartbanner__exit").addEventListener("click", () => {
    document.querySelector(".smartbanner").classList.add("d-none");
    document.cookie = `pwa-banner-closed=true; path=/; max-age=${
      60 * 60 * 24 * 3
    };`;
  });
});
