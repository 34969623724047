import { Controller } from "stimulus";
import { Notyf } from "notyf";
// import "notyf/notyf.min.css";

export default class extends Controller {
  static targets = ["content", "variant"];

  connect() {
    const notyf = new Notyf({
      duration: 3000,
      position: { x: "center", y: "top" },
    });

    const text = this.contentTarget.textContent;
    const variant = this.variantTarget.textContent;

    if (!text) {
      return;
    }

    if (variant === "error") {
      notyf.error(text);
    }

    if (variant === "success") {
      notyf.success(text);
    }
  }
}
