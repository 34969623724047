import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "tabPanel"];

  initialize() {
    console.log("CHUJ");
    this.showTab();
  }

  change(e) {
    this.index = this.tabTargets.indexOf(e.target.parentNode);
    this.showTab(this.index);
  }

  showTab() {
    // console.log(this.tabPanelTargets);

    this.tabPanelTargets.forEach((el, i) => {
      if (i == this.index) {
        el.classList.remove("d-none");
      } else {
        el.classList.add("d-none");
      }
    });

    // this.tabTargets.forEach((el, i) => {
    //   console.log(el, i);
    //   if (i == this.index) {
    //     el.classList.add("is-active");
    //   } else {
    //     el.classList.remove("is-active");
    //   }
    // });
  }

  showNext(event) {
    // console.log(event.target.dataset.tab);
    this.index = event.target.dataset.tab;
  }

  get index() {
    return parseInt(this.data.get("index"));
  }

  set index(value) {
    this.data.set("index", value);
    this.showTab();
  }
}
